<template>
  <v-row
    class="px-10"
    dense
  >
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="client.email"
        outlined
        type="email"
        label="Email"
        rounded
        dense
        autocomplete="off"
        :rules="emailRules"
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="client.password"
        autocomplete="off"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
        type="text"
        outlined
        label="Senha"
        rounded
        dense
        @click:append="showPassword = !showPassword"
      />
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <v-text-field
        v-model="password"
        autocomplete="off"
        :append-icon="showPassword ? 'fas fa-eye' : 'fas fa-eye-slash'"
        type="text"
        :rules="[
          this.password == this.client.password || 'Senhas estão diferentes',
        ]"
        outlined
        label="Repetir senha"
        rounded
        dense
        @click:append="showPassword = !showPassword"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object,
    password: String,
  },
  data: () => ({
    showPassword: false,
    emailRules: [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ]
  }),
}
</script>
